<template>
  <div class="user-state">
    <template v-if="authenticated">
      <NuxtLink
        v-if="canAccessProfile"
        class="user-state__link"
        :class="{ 'user-state__link--active': onProfile }"
        :to="{ name: 'jobs-search_code___de' }"
      >
        {{ $t('components.controls.userState.profile') }}
      </NuxtLink>
      <a
        class="user-state__link user-state__link--login user-state__link--bold"
        :href="link"
      >
        <icon name="logout" class="user-state__icon-authenticated" />
        {{ $t('components.controls.userState.logout') }}
      </a>
      <div v-if="!isJobmailReady" class="user-state user-state__link-button">
        <a class="user-state__button" :href="register">
          {{ $t('components.controls.userState.startTest') }}
        </a>
      </div>
    </template>
    <template v-else>
      <NuxtLink
        class="user-state__link"
        :class="{ 'user-state__link--active': onJobSearch }"
        :to="{ name: 'jobs-search_code___de' }"
      >
        {{ $t('components.controls.userState.jobSearch') }}
      </NuxtLink>
      <a class="user-state__link user-state__link--login" :href="link">
        <icon v-if="isDesktop" name="login" class="user-state__icon-login" />
        {{ $t('components.controls.userState.login') }}
      </a>
      <div class="user-state user-state__link-button">
        <a class="user-state__button" :href="register">
          {{ $t('components.controls.userState.startTest') }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserState',
  data() {
    return {
      profilePaths: ['jobs', 'suchprofil', 'testergebnis', 'einstellungen'],
      isDesktop: false
    }
  },
  computed: {
    ...mapGetters('user', ['email', 'isJobmailReady']),
    authenticated() {
      return this.email !== ''
    },
    link() {
      if (this.authenticated) {
        return `${this.$config.ZEIT_SSO_LOGOUT_URL}?url=${this.$config.PRODUCTION_URL}/&entry_service=boa_bot`
      } else {
        return `${this.$config.ZEIT_SSO_LOGIN_URL}?url=${this.$config.PRODUCTION_URL}/&entry_service=boa_bot`
      }
    },
    register() {
      return `${this.$config.ZEIT_SSO_REGISTER_URL}?url=${this.$config.PRODUCTION_URL}/&entry_service=boa_bot`
    },
    canAccessProfile() {
      return this.isJobmailReady
    },
    onProfile() {
      const basename = this.$nuxt.$route.path.split('/')[1]
      return this.profilePaths.includes(basename)
    },
    onJobSearch() {
      return this.$nuxt.$route.path === '/jobs'
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.isDesktop = window.innerWidth >= 1024
    }
  }
}
</script>

<style lang="scss" scoped>
.user-state {
  display: flex !important;
  justify-content: center;
  align-items: center;

  @include breakpoint(medium down) {
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
  }

  &__button {
    @include white-button-primary-border;

    @include breakpoint(medium down) {
      padding: auto spacing(3);
      text-decoration: none;
      flex-basis: 100%;
      display: block;
      width: 100%;
      max-height: spacing(7);
      transition: $transition-speed;
      background-color: color(white);
    }
  }

  &__link {
    @include meta;

    color: color(black, base);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: spacing(3) spacing(4);
    border-bottom: 1px solid color(gray, light);
    flex-basis: 100%;
    display: block;
    width: 100%;
    transition: $transition-speed;
    background-color: color(white);

    @include breakpoint(large) {
      padding: 0;
      margin-right: spacing(7);
      border-bottom: none;
      width: auto;
      flex-basis: auto;
    }

    &:hover {
      color: color(primary, base);

      svg {
        transition: $transition-speed;
      }
    }

    &--login {
      display: flex;
      align-items: center;
    }

    &--bold {
      font-weight: $font-weight-bold;
    }

    &--active {
      color: color(primary, base);
    }
  }

  &__link-button {
    @include meta;

    color: color(black, base);
    text-decoration: none;
    letter-spacing: 0.1em;
    padding: spacing(3) spacing(4);
    border-bottom: 1px solid color(gray, light);
    flex-basis: 100%;
    display: block;
    width: 100%;
    transition: $transition-speed;
    background-color: color(white);

    @include breakpoint(large) {
      border-bottom: none;
      padding: 0;
    }
  }

  &__icon-authenticated {
    height: 24px;
    width: 24px;
    margin-right: spacing(1);
    fill: color(black, base);
  }

  &__icon-login {
    height: 16px;
    width: 12px;
    margin-right: spacing(2);
  }

  &__link:hover > &__icon-authenticated {
    fill: color(primary, base);
  }

  &__link:hover > &__icon-login {
    color: color(primary, base);
  }
}
</style>
