export const jobLevelProfile = [
  {
    label: 'Angestellt (Berufseinstieg / Junior)',
    value: 'EMPLOYED_JUNIOR',
    codeValue: 1
  },
  {
    label: 'Angestellt (Midlevel / Senior)',
    value: 'EMPLOYED_SENIOR',
    codeValue: 2
  },
  {
    label: 'Wissenschaftlicher Mitarbeiter',
    value: 'SCIENTIFIC_ASSISTANT',
    codeValue: 3
  },
  { label: 'Berufsausbildung', value: 'JOB_TRAINING', codeValue: 4 },
  {
    label: 'Studentische Hilfskraft / Werkstudent',
    value: 'WORKING_STUDENT',
    codeValue: 5
  },
  { label: 'Trainee', value: 'TRAINEE', codeValue: 6 },
  { label: 'Volontariat', value: 'VOLUNTEER', codeValue: 7 },
  { label: 'Praktikum', value: 'INTERNSHIP', codeValue: 8 }
]

export const jobLevelExperience = [
  ...jobLevelProfile,
  { label: 'Selbständig / Freiberuflich', value: 'FREELANCE' }
]
