export default function ({ app, error: errorPage }) {
  if (
    app.store.getters['user/email'] &&
    !app.store.getters['user/isProfileLoaded']
  ) {
    return app.store.dispatch('user/profileLoad').catch(() => {
      errorPage({ statusCode: 500 })
    })
  }
}
