export const state = () => ({
  webtrekkConfig: null
})

export const mutations = {
  webtrekkConfigSet(state, config) {
    state.webtrekkConfig = config
  }
}

export const actions = {
  webtrekkConfigure({ commit }, config) {
    commit('webtrekkConfigSet', config)
  }
}
