import _isEqual from 'lodash/isEqual'
import _setWith from 'lodash/setWith'
import _cloneDeep from 'lodash/cloneDeep'

import questionsQuery from './graphql/queries/questions.gql'
import updateUserProfileMutation from './graphql/mutations/update-user-profile.gql'
import updatePartialUserMutation from './graphql/mutations/update-partial-user.gql'
import getUserProfileQuery from './graphql/queries/get-user-profile.gql'
import getUserResultsQuery from './graphql/queries/get-user-result.gql'
import jobCountFilterQuery from './graphql/queries/job-count-filter.gql'
import { workplacesDefaultValues } from '@/lib/data/workplaces.js'
import {
  omitEmpties,
  userProfileToJobsFilter
} from '@/lib/util/search-filters.js'
import * as UserParsing from '@/lib/util/user-parsing.js'

export const state = () => ({
  questions: [],
  results: {
    topThreeDimensions: [],
    dimensionCategories: []
  },
  // AC-2944 the disconnect between benu and schu for possible
  // job_field values makes it necessary to ignore deprecated values
  // while handling job_fields in benu. However we need to save and merge
  // them for profile updates to make sure no data is lost
  // while deploying change in job_field values.
  deprecatedProfileData: {},
  profile: {
    // In quiz order
    personalities: {},
    careerLevel: null,
    studyCourses: [],
    jobMailSettings: {
      isSearching: null,
      jobMailFrequency: 'TWICE_PER_WEEK'
    },
    jobFields: [],
    jobLevels: [],
    compensation: null,
    workplaces: workplacesDefaultValues(),
    languages: [],
    personalDetails: {
      firstName: ''
    },
    isQuizComplete: false,
    isJobmailReady: false
  },
  matchingJobsCount: 0,
  email: '',
  profileSaved: true,
  profileLoaded: false,
  openProfileRestoredModal: false
})

export const mutations = {
  questionsSet(state, questions) {
    state.questions = questions
  },
  resultsSet(state, results) {
    state.results = results
  },
  profileSet(state, profile) {
    state.profile = profile
  },
  profileAssign(state, change) {
    state.profile = Object.entries(change).reduce((profile, [key, value]) => {
      _setWith(profile, key, value, Object)
      return profile
    }, _cloneDeep(state.profile))
  },
  deprecatedProfileDataSet(state, deprecatedProfileData) {
    state.deprecatedProfileData = deprecatedProfileData
  },
  profileSavedSet(state, profileSaved) {
    state.profileSaved = profileSaved
  },
  profileLoadedSet(state, profileLoaded) {
    state.profileLoaded = profileLoaded
  },
  openProfileRestoredModalSet(state, openProfileRestoredModal) {
    state.openProfileRestoredModal = openProfileRestoredModal
  },
  emailSet(state, email) {
    state.email = email
  },
  matchingJobsCountSet(state, matchingJobsCount) {
    state.matchingJobsCount = matchingJobsCount
  }
}

export const actions = {
  questionsLoad({ state, commit }) {
    if (state.questions.length !== 0) {
      return Promise.resolve()
    }

    return this.app.apolloProvider.defaultClient
      .query({ query: questionsQuery })
      .then(({ data }) => {
        commit(
          'questionsSet',
          data.questions.map(question => ({ ...question, value: null }))
        )
      })
  },
  profileSet({ state, commit }, profile) {
    if (
      !_isEqual(
        UserParsing.parseUserFromQuiz(state.profile),
        UserParsing.parseUserFromQuiz(profile)
      )
    ) {
      commit('profileSavedSet', false)
    }

    commit('profileSet', profile)
  },
  profileAssign({ state, commit }, change) {
    commit('profileSavedSet', false)
    commit('profileAssign', change)
  },
  resultsLoad({ commit }) {
    return this.app.apolloProvider.defaultClient
      .query({ query: getUserResultsQuery })
      .then(({ data }) => {
        const results = UserParsing.parseResultsFromBackend(data.getUser)
        commit('resultsSet', results)
      })
  },
  profileLoad({ commit }) {
    return this.app.apolloProvider.defaultClient
      .query({ query: getUserProfileQuery })
      .then(({ data }) => {
        const parsedData = UserParsing.parseUserFromBackend(data.getUser)
        commit('profileSet', parsedData.profile)
        commit('deprecatedProfileDataSet', parsedData.deprecated)
        commit('profileLoadedSet', true)
        commit('profileSavedSet', true)
        commit('openProfileRestoredModalSet', true)

        return true
      })
  },
  onProfileSaveSuccess({ state, commit }, data) {
    commit('profileSet', { ...state.profile, ...data.updateUser })
    commit('profileSavedSet', true)
    commit('profileLoadedSet', true)
  },
  profileSave({ state, dispatch }) {
    if (state.profileSaved) {
      return Promise.resolve('unchanged')
    }

    const profile = {
      ...UserParsing.parseUserFromQuiz(
        state.profile,
        state.deprecatedProfileData
      )
    }
    return this.app.apolloProvider.defaultClient
      .mutate({ mutation: updateUserProfileMutation, variables: { profile } })
      .then(({ data }) => dispatch('onProfileSaveSuccess', data))
  },
  updateFirstName({ state, dispatch }) {
    if (state.profileSaved) {
      return Promise.resolve('unchanged')
    }

    const profile = {
      ...UserParsing.parseUserFromQuiz(
        state.profile,
        state.deprecatedProfileData
      )
    }
    const params = { firstName: profile.firstName }
    return this.app.apolloProvider.defaultClient
      .mutate({ mutation: updatePartialUserMutation, variables: { params } })
      .then(({ data }) => dispatch('onProfileSaveSuccess', data))
  },
  updateNotificationSettings({ state, dispatch }) {
    if (state.profileSaved) {
      return Promise.resolve('unchanged')
    }

    const profile = {
      ...UserParsing.parseUserFromQuiz(
        state.profile,
        state.deprecatedProfileData
      )
    }
    const params = {
      isSearching: profile.isSearching,
      jobMailFrequency: profile.jobMailFrequency
    }
    return this.app.apolloProvider.defaultClient
      .mutate({ mutation: updatePartialUserMutation, variables: { params } })
      .then(({ data }) => dispatch('onProfileSaveSuccess', data))
  },
  profileRestoredModalOpen({ state, commit, dispatch }) {
    if (!state.openProfileRestoredModal) {
      return
    }

    commit('openProfileRestoredModalSet', false)

    return dispatch(
      'app/modalOpen',
      this.app.i18n.t('middleware.model.restoredModal'),
      { root: true }
    )
  },
  setEmail({ state, commit }) {
    if (state.email !== '') {
      return Promise.resolve()
    }

    return this.app.apolloProvider.defaultClient
      .query({ query: getUserProfileQuery })
      .then(({ data }) => {
        commit('emailSet', data.getUser.email)
      })
      .catch(error => {
        throw error
      })
  },
  unsetEmail({ commit }) {
    commit('emailSet', '')
  },
  jobCountFilterLoad({ state, commit }) {
    return this.app.apolloProvider.defaultClient
      .query({
        query: jobCountFilterQuery,
        variables: {
          filter: omitEmpties(userProfileToJobsFilter(state.profile))
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        commit('matchingJobsCountSet', data.jobCountFilter)
      })
  }
}

export const getters = {
  isProfileSaved: state => {
    return state.profileSaved
  },
  isProfileLoaded: state => {
    return state.profileLoaded
  },
  isQuizComplete: state => {
    return state.profile && !!state.profile.isQuizComplete
  },
  isJobmailReady: state => {
    return state.profile && !!state.profile.isJobmailReady
  },
  results: state => {
    return state.results
  },
  email: state => {
    return state.email
  },
  openProfileRestoredModal: state => {
    return state.openProfileRestoredModal
  }
}
