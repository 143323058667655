export function silentRoutePush(router, options) {
  if (!process.browser) {
    return
  }
  // Change "URL" and push it to history without rerendering
  const { href } = router.resolve(options)
  window.history.pushState({}, null, href)
}

export function silentRouteReplace(router, options) {
  if (!process.browser) {
    return
  }
  // Change "URL" without rerendering
  const { href } = router.resolve(options)
  window.history.replaceState(window.history.state, null, href)
}
