import _debounce from 'lodash/debounce'

// debounce profile save event to stop impatient users from spamming the backend with profile updates
export default _debounce(
  async function ({ app }) {
    // This is called in SSR, but isProfileSaved will always be false for those cases. In the client, the middleware is run
    // on page transitions, so we'll save if the user has made changes on the previous page.
    if (!app.store.getters['user/isProfileSaved']) {
      await app.store.dispatch('user/profileSave').catch(() => {
        // do we even care?
      })
    }
  },
  500,
  { leading: true, trailing: false }
)
