export const state = () => ({
  modal: {
    opened: false,
    title: '',
    body: '',
    button: ''
  },
  nav: {
    opened: false
  },
  referrer: []
})

export const mutations = {
  modalToggle(state) {
    state.modal.opened = !state.modal.opened
  },
  modalTitleSet(state, title) {
    state.modal.title = title
  },
  modalBodySet(state, body) {
    state.modal.body = body
  },
  modalButtonSet(state, button) {
    state.modal.button = button
  },
  navToggle(state) {
    state.nav.opened = !state.nav.opened
  },
  pushReferrer(state, referrer) {
    state.referrer.push(referrer)
    if (state.referrer.length > 2) {
      state.referrer.shift()
    }
  }
}

export const actions = {
  modalToggle({ commit }) {
    return commit('modalToggle')
  },
  modalOpen({ commit }, modal) {
    commit('modalTitleSet', modal.title)
    commit('modalBodySet', modal.body)
    commit('modalButtonSet', modal.button)
    return commit('modalToggle')
  },
  navToggle({ commit }) {
    return commit('navToggle')
  },
  pushReferrer({ commit }, referrer) {
    return commit('pushReferrer', referrer)
  }
}

export const getters = {
  isModalOpen: state => {
    return state.modal.opened
  },
  modalTitle: state => {
    return state.modal.title
  },
  modalBody: state => {
    return state.modal.body
  },
  modalButton: state => {
    return state.modal.button
  },
  isNavOpen: state => {
    return state.nav.opened
  },
  referrer: state => {
    return state.referrer.length === 2 ? state.referrer[0] : undefined
  }
}
