import _map from 'lodash/map'
import _mapValues from 'lodash/mapValues'
import _isNull from 'lodash/isNull'
import _get from 'lodash/get'
import dayjs from 'dayjs'
import jobFields from '@/lib/data/job-fields.js'

import { workplacesDefaultValues } from '@/lib/data/workplaces'

export function parseResultsFromBackend(user) {
  return {
    topThreeDimensions: user.quizResult.topThreeDimensions,
    dimensionCategories: user.quizResult.dimensionCategories
  }
}

const KNOWN_JOB_FIELDS = jobFields.map(field => field.value)

export function parseUserFromBackend(user) {
  const jobFields = user.jobFields.reduce(
    (acc, jobField) => {
      if (KNOWN_JOB_FIELDS.includes(jobField)) {
        acc.known.push(jobField)
      } else {
        acc.deprecated.push(jobField)
      }
      return acc
    },
    { known: [], deprecated: [] }
  )

  const profile = {
    personalities: mapFromPersonalityList(user.personalities),
    careerLevel: user.careerLevel,
    studyCourses: user.studyCourses,
    jobMailSettings: {
      isSearching: user.isSearching,
      jobMailFrequency: user.jobMailFrequency
    },
    jobFields: jobFields.known,
    jobLevels: user.jobLevels,
    compensation: user.compensation,
    workplaces: mapFromWorkplaces(user.workplaces),
    languages: user.languages,
    personalDetails: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phone: user.phone || '',
      birthday: parseBirthdayFromBackend(user.birthday)
    },
    isQuizComplete: user.isQuizComplete,
    isJobmailReady: user.isJobmailReady
  }

  const deprecated = {
    jobFields: jobFields.deprecated
  }

  return { profile, deprecated }
}

export function parseUserFromQuiz(quizModel, deprecatedProfileData = {}) {
  const birthday = _get(quizModel, 'personalDetails.birthday')
  return {
    personalities: listFromPersonalityMap(_get(quizModel, 'personalities')),
    careerLevel: _get(quizModel, 'careerLevel'),
    studyCourses: _get(quizModel, 'studyCourses'),
    isSearching: _get(quizModel, 'jobMailSettings.isSearching'),
    jobMailFrequency: _get(quizModel, 'jobMailSettings.jobMailFrequency'),
    jobFields: [
      ...new Set([
        ..._get(deprecatedProfileData, 'jobFields', []),
        ..._get(quizModel, 'jobFields', [])
      ])
    ],
    jobLevels: _get(quizModel, 'jobLevels'),
    compensation: _get(quizModel, 'compensation'),
    workplaces: _get(quizModel, 'workplaces'),
    languages: _get(quizModel, 'languages'),
    firstName: _get(quizModel, 'personalDetails.firstName'),
    lastName: _get(quizModel, 'personalDetails.lastName'),
    phone: _get(quizModel, 'personalDetails.phone'),
    birthday: parseBirthdayFromQuiz(birthday)
  }
}

export function mapFromPersonalityList(list) {
  return list.reduce((acc, { itemNumber, value }) => {
    acc[itemNumber] = value
    return acc
  }, {})
}

export function listFromPersonalityMap(map) {
  return _map(map, (value, itemNumber) => ({
    itemNumber: parseInt(itemNumber, 10),
    value
  }))
}

function mapFromWorkplaces(workplaces) {
  const defaults = workplacesDefaultValues()
  if (_isNull(workplaces)) {
    return defaults
  } else {
    return _mapValues(workplaces, (val, key) => val || defaults[key])
  }
}

function parseBirthdayFromBackend(birthday) {
  if (!birthday) {
    return { date: null, month: null, year: null }
  }

  const birthdayMoment = dayjs(birthday)

  return {
    date: birthdayMoment.date(),
    month: birthdayMoment.month() + 1,
    year: birthdayMoment.year()
  }
}

function parseBirthdayFromQuiz(birthday) {
  if (!birthday || !birthday.date) {
    return null
  }

  return dayjs({
    date: birthday.date,
    month: birthday.month - 1,
    year: birthday.year
  }).format('YYYY-MM-DD')
}
