export default {
  inserted: (el, binding, vnode) => {
    vnode.$scrollOberserver = new IntersectionObserver(
      entries => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) {
            binding.value.handler()
          } else if (binding.value.offHandler) {
            binding.value.offHandler()
          }
        })
      },
      {
        rootMargin: binding.value.margin || '0px',
        threshold: binding.value.threshold || 0,
        // need to find scroll parent if we want to use this outside of document scroll
        root: undefined
      }
    )
    vnode.$scrollOberserver.observe(el)
  },
  unbind: (_el, _binding, vnode) => {
    vnode.$scrollOberserver && vnode.$scrollOberserver.disconnect()
  }
}
