import _at from 'lodash/at'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import _join from 'lodash/join'
import _merge from 'lodash/merge'
import _reduce from 'lodash/reduce'
import {
  CUSTOM_PARAMS,
  CUSTOM_PARAMS_PER_CONTEXT,
  getDefaultSettings
} from '@/lib/tracking/webtrekk/constants.js'

const commons = {
  createDefaultWebtrekkConfig() {
    return {
      scope: 'redaktion',
      pageType: '',
      department: 'campus',
      sourceType: 'zede',
      subdepartment: 'stellenmarkt',
      cluster: ''
    }
  },
  createContentId(config) {
    const mergedConfig = _merge(this.createDefaultWebtrekkConfig(), config)
    const contentId = _at(mergedConfig, [
      'scope',
      'department',
      'subdepartment',
      'cluster',
      'pageType',
      'sourceType'
    ])
    return _join(contentId, '.') + '|' + config.url
  },
  createContentGroup(config) {
    const mergedConfig = _merge(this.createDefaultWebtrekkConfig(), config)
    return {
      1: mergedConfig.scope,
      2: mergedConfig.pageType,
      3: mergedConfig.department,
      4: mergedConfig.sourceType,
      5: mergedConfig.subdepartment,
      6: mergedConfig.cluster
    }
  },
  createCustomParameter(context, config) {
    if (!config.customParameter) {
      return {}
    }

    const customParamsPerContext = _reduce(
      config.customParameter,
      function (acc, value, key) {
        const paramsId = CUSTOM_PARAMS[key]
        const allowedParam = _includes(CUSTOM_PARAMS_PER_CONTEXT[context], key)
        if (paramsId && allowedParam && value != null) {
          acc[paramsId] = value
        }
        return acc
      },
      {}
    )
    return customParamsPerContext
  }
}

const pixels = {
  init(pixelConfig, vue) {
    return this.createPixels(pixelConfig, vue)
  },
  createPixels(pixelConfig, vue) {
    if (!pixelConfig) {
      return
    }

    const wts = []
    const defaultPixelSettings = getDefaultSettings(vue)
    Object.keys(defaultPixelSettings).forEach(context => {
      try {
        const newPixelConfig = {
          contentId: commons.createContentId(pixelConfig),
          customParameter: commons.createCustomParameter(context, pixelConfig),
          contentGroup: commons.createContentGroup(pixelConfig),
          ...this.createZonappConfig()
        }
        Object.keys(defaultPixelSettings[context]).forEach(key => {
          newPixelConfig[key] = defaultPixelSettings[context][key]
        })
        const wt = new webtrekkV3(newPixelConfig)
        wt.setReferrer(this.referrer(vue))
        wt.customParameter[12] = this.agentType()

        wts.push(wt)
      } catch (e) {
        vue.$sentry.captureException(e)
      }
    })

    return wts
  },
  referrer(vue) {
    const ref = vue.$store.getters['app/referrer']
    return ref === undefined ? document.referrer : ref
  },
  agentType() {
    if (this.isZonApp()) {
      return 'zonapp'
    } else if (window.innerWidth >= 1024) {
      return 'desktop.site'
    } else {
      return 'mobile.site'
    }
  },
  isZonApp() {
    return /zonapp\/[^/]+\/(?!1\.)/.test(navigator.userAgent.toLowerCase())
  },
  createZonappConfig() {
    return this.isZonApp() ? { sendViaSDK: true } : {}
  }
}

const noScriptPixel = {
  createNoScriptPixel(pixelConfig, context) {
    return (
      this.createNoScriptContentId(pixelConfig) +
      this.createNoScriptContentGroup(pixelConfig) +
      this.createNoScriptCustomParameter(context, pixelConfig)
    )
  },
  createNoScriptContentId(config) {
    const url = [
      'wt.pl?p=445', // pixel version
      commons.createContentId(config),
      '0,0,0,0,0,0,0,0'
    ]
    return _join(url, ',')
  },
  createNoScriptContentGroup(config) {
    const url = ['']
    const contentGroup = commons.createContentGroup(config)
    _forEach(contentGroup, (value, key) => {
      url.push(`cg${key}=${value}`)
    })

    return _join(url, '&')
  },
  createNoScriptCustomParameter(context, config) {
    const url = ['']
    const customParams = commons.createCustomParameter(context, config)
    _forEach(customParams, (value, key) => {
      url.push(`cp${key}=${value}`)
    })

    return _join(url, '&')
  }
}

export default {
  init: (pixelConfig, vue) => {
    const wts = pixels.init(pixelConfig, vue)
    wts.forEach(wt => wt.sendinfo())
    return wts
  },
  createNoScriptPixel: (pixelConfig, context) =>
    noScriptPixel.createNoScriptPixel(pixelConfig, context)
}
