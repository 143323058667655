import { loadScript } from '@/lib/util/load-script'

const DOMAIN = 'data-af9f3dfb33.zeit.de'

export default {
  /* eslint-disable prefer-promise-reject-errors */
  send(path, debugOnly) {
    if (!path) {
      return Promise.reject()
    }

    const iamMode = 1
    const st = this.pageLayout() === 'desktop' ? 'zeitonl' : 'mobzeit'
    const cp = this.getPageType(path)
    const co = path
    const iamData = {
      st,
      cp,
      sv: this.pageLayout() === 'desktop' ? 'i2' : 'mo',
      co
    }
    const measurementManager = {
      configure: { dn: DOMAIN, st },
      pageview: { co, cp }
    }

    if (debugOnly) {
      // eslint-disable-next-line no-console
      console.log('IVW added:', { config: { iamData, measurementManager } })
      return Promise.resolve()
    }

    return Promise.allSettled([
      loadScript('https://script.ioam.de/iam.js')
        .then(() => !(window.iom && window.iom.c) && Promise.reject())
        .then(() => {
          window.iom.c(iamData, iamMode)
        })
        .catch(() => {}), // ignore all errors also related to the pixel
      loadScript(`https://${DOMAIN}/iomm/latest/bootstrap/stub.js`)
        .then(() =>
          loadScript(`https://${DOMAIN}/iomm/latest/manager/base/es5/bundle.js`)
        )
        .then(() => !window.IOMm && Promise.reject())
        .then(() => {
          window.IOMm('configure', measurementManager.configure)
          window.IOMm('pageview', measurementManager.pageview)
        })
        .catch(() => {}) // ignore all errors also related to the pixel
    ])
  },
  /* eslint-enable prefer-promise-reject-errors */
  pageLayout() {
    return window.innerWidth > 768 ? 'desktop' : 'mobile'
  },
  getPageType(path) {
    const relevantPart = path.split('/')[1]
    const pageTypeConfig = {
      '': 'startseite',
      anmelden: 'anmelden',
      berufstest: 'bot'
    }
    return `BOA/${pageTypeConfig[relevantPart]}/bild-text`
  }
}
