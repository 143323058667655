<template>
  <div />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'GoogleTagManager',
  head() {
    return {
      script: [
        {
          innerHTML: this.putGoogleTagManagerScript(),
          body: true
        }
      ],
      noscript: [
        {
          innerHTML: this.putGoogleTagManagerNoScript(),
          body: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters('consent', ['consentForGOOGLE'])
  },
  watch: {
    consentForGOOGLE: {
      handler(consenting) {
        if (this.$isServer) {
          return
        }

        if (consenting) {
          this.setupGoogleTagManager()
        } else {
          this.teardownGoogleTagManager()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('consent', ['gtmTrackingSetLoaded']),
    setupGoogleTagManager() {
      this.gtmTrackingSetLoaded(true)
    },
    teardownGoogleTagManager() {
      this.gtmTrackingSetLoaded(false)
    },
    putGoogleTagManagerScript() {
      if (this.consentForGOOGLE) {
        const snippet =
          "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
          "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
          "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
          "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
          "})(window,document,'script','dataLayer','GTM-W2WV2WD');" +
          'if (!window.dataLayer) {' +
          'window.dataLayer = []' +
          '}'
        return snippet
      } else {
        return ''
      }
    },
    putGoogleTagManagerNoScript() {
      if (this.consentForGOOGLE) {
        return '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W2WV2WD" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
      } else {
        return ''
      }
    }
  }
}
</script>
