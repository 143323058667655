export default {
  watch: {
    webtrekk: {
      handler(webtrekkConfig) {
        if (webtrekkConfig) {
          this.$store.dispatch('tracking/webtrekkConfigure', webtrekkConfig)
        }
      },
      immediate: true
    }
  }
}
