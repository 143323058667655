import Vue from 'vue'
// eslint-disable-next-line import/no-webpack-loader-syntax
import svgSpriteIconsFileContent from '!!raw-loader!../assets/sprite/gen/icons.svg'

Vue.component('SvgSpriteIcons', {
  render(createElement) {
    return createElement('div', {
      attrs: { style: 'display: none;' },
      domProps: {
        innerHTML: svgSpriteIconsFileContent.replace(/<\?xml.*?\?>/gi, '')
      }
    })
  }
})
