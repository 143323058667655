import _omit from 'lodash/omit'
import _pick from 'lodash/pick'

export function getDefaultSettings(vue) {
  return {
    jobMarkets: {
      trackId: vue.$config.WEBTREKK_TRACK_ID_JOB_MARKETS,
      trackDomain: 'audev.zeit.de'
    },
    q3: {
      trackId: vue.$config.WEBTREKK_TRACK_ID_QTHREE,
      trackDomain: 'audev.zeit.de'
    }
  }
}

export const CUSTOM_PARAMS = {
  jobStatus: 1, // Indicates wether a job offer is active (Stellenmärkte)
  jobArea: 2, // Information of the area within a branch of a job offering
  pageNumberQ3: 3, // Number of the page within a set of pages, like the BOT question pages. Identical with ID 24
  jobLevel: 4, // Job level of a job offering, like trainee, senior (Stellenmärkte)
  employerType: 5, // NOT USED YET
  countries: 6, // NOT USED YET
  languages: 7, // NOT USED YET
  startReg: 8, // NOT USED YET
  jobBranch: 9, // Information about the branch of a job offering (Stellenmärkte)
  jobWorkingTime: 10, // Information about the working times of a job offering, like full time, part time
  searchTerms: 11, // NOT USED YET
  pageLayout: 12, // Indicates the IVW version of the page layout, differentiates between mobile and desktop layout
  jobEmployerName: 13, // Name of the employer
  jobLocation: 14, // Location of place of work
  jobCount: 15, // Number of job offerings on a search result page
  lastPublished: 16, // Date of publication of a page
  errorMessages: 17, // NOT USED YET
  dateEnd: 18, // NOT USED YET
  jobId: 19, // NOT USED YET
  deviceOrientation: 20, // NOT USED YET
  userStatusAcademics: 21, // NOT USED YET
  topJob: 22, // Indicates if and which Anzeigenverstärker was active on page request
  zeitSso: 23, // Indicates if the user was logged into her SSO account on page request
  pageNumberJobMarkets: 24, // Number of the page within a set of pages, like the BOT question pages. Identical with ID 3
  personalLayout: 25, // NOT USED YET
  pageType: 26, // NOT USED YET
  assetParam: 27, // NOT USED YET
  matchRate: 28, // NOT USED YET
  jobSourceUrl: 29, // NOT USED YET
  qualityOfJob: 30, // NOT USED YET
  accountLifetime: 31, // NOT USED YET
  protocol: 32, // Protocol of the page, like https
  datePostedSet: 33, // NOT USED YET
  domain: 34, // NOT USED YET
  descriptionSet: 35, // NOT USED YET
  hiringOrganizationSet: 36, // NOT USED YET
  jobLocationSet: 37, // NOT USED YET
  titleSet: 38, // NOT USED YET
  validThroughSet: 39, // NOT USED YET
  employmentTypeSet: 40, // NOT USED YET
  fullUrl: 41, // page url
  contractType: 42 // NOT USED YET
}

export const CLICK_TRACKING_PARAMS = {
  page_type: 5,
  content_element: 6,
  content_element_pos: 'cep',
  event_element: 7,
  event_element_pos: 8,
  action_type: 9,
  id: 10,
  target: 'ct'
}

export const CUSTOM_PARAMS_PER_CONTEXT = {
  jobMarkets: Object.keys(_omit(CUSTOM_PARAMS, ['pageNumberQ3'])),
  q3: Object.keys(
    _pick(CUSTOM_PARAMS, ['pageNumberQ3', 'pageLayout', 'zeitSso', 'protocol'])
  )
}
