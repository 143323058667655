import dayjs from 'dayjs'
import 'dayjs/locale/de'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import objectSupport from 'dayjs/plugin/objectSupport'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.locale('de')
dayjs.extend(isSameOrBefore)
dayjs.extend(localeData)
dayjs.extend(objectSupport)
dayjs.extend(relativeTime)
