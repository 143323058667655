<template>
  <div />
</template>

<script>
import _debounce from 'lodash/debounce'
import { mapGetters, mapState } from 'vuex'

import webtrekkTracking from '@/lib/tracking/webtrekk.js'
import { getDefaultSettings } from '@/lib/tracking/webtrekk/constants.js'
import { loadScript } from '@/lib/util/load-script'

const REQUIRED_CLICKTRACK_PARAMETERS = ['ct', 4, 5, 6, 7, 8, 9]

export default {
  data() {
    return {
      wts: []
    }
  },
  head() {
    return {
      noscript: [
        {
          innerHTML: this.buildNoScriptPixel(this.webtrekkConfig),
          body: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters('consent', ['consentForWEBTREKK']),
    ...mapGetters('user', ['email']),
    ...mapState('tracking', ['webtrekkConfig'])
  },
  watch: {
    webtrekkConfig: {
      handler() {
        this.debouncedSendPixel()
      },
      immediate: true
    }
  },
  created() {
    if (this.$isServer) {
      return
    }
    this.$nuxt.$on('trackClick', this.trackClick)
  },
  beforeDestroy() {
    if (this.$isServer) {
      return
    }
    this.$nuxt.$off('trackClick')
  },
  methods: {
    debouncedSendPixel: _debounce(function () {
      this.sendPixel()
    }, 1000),
    sendPixel() {
      if (this.$isServer) {
        return
      }

      if (this.consentForWEBTREKK) {
        loadScript(
          'https://scripts.zeit.de/static/js/webtrekk/webtrekk_v4.0.5.js'
        ).then(() => {
          const config = this.webtrekkConfig
          if (config) {
            this.buildPixel(config)
          }
        })
      }
    },
    buildPixel(params) {
      this.wts = webtrekkTracking.init(this.buildParamsForPixel(params), this)
      return this.wts
    },
    trackClick(params) {
      if (this.$isServer) {
        return
      }

      if (this.consentForWEBTREKK) {
        params[4] = this.getBreakpoint() // Set breakpoint parameter ck4
        params[8] = params[8] || '1' // set event_element_pos parameter ck8

        if (this.validTrackingParameters(params)) {
          loadScript(
            'https://scripts.zeit.de/static/js/webtrekk/webtrekk_v4.0.5.js'
          ).then(() => {
            this.wts
              .filter(
                wt => wt.trackId === this.$config.WEBTREKK_TRACK_ID_JOB_MARKETS
              )
              .forEach(wt =>
                wt.sendinfo({ linkId: params.ct, customClickParameter: params })
              )
          })
        } else if (this.$nuxt.$config.BENU_STAGE !== 'prod') {
          // eslint-disable-next-line no-console
          console.warn(
            'Tracking event missing required parameters! Will not be sent.',
            params
          )
        }
      }
    },
    buildNoScriptPixel(params) {
      if (!params) {
        return ''
      }
      const defaultPixelSettings = getDefaultSettings(this)
      return Object.keys(defaultPixelSettings)
        .map(context => {
          const trackingConf = defaultPixelSettings[context]
          try {
            const noScriptParams = webtrekkTracking.createNoScriptPixel(
              this.buildParamsForPixel(params),
              context
            )
            const url = `https://${trackingConf.trackDomain}/${trackingConf.trackId}/${noScriptParams}`

            return `<img alt="" width="1" height="1" src="${url}" />`
          } catch (e) {
            return this.$sentry.captureException(e)
          }
        })
        .join('')
    },
    getUserState() {
      return this.email ? 'angemeldet' : 'nicht_angemeldet'
    },
    getProtocolAndUrl(params) {
      const [protocol, fullUrl] =
        `${this.$config.PRODUCTION_URL}${this.$route.path}`.split('://')
      let url = ''
      if (params.errorStatusCode) {
        url =
          `${this.$config.PRODUCTION_URL}/error/${params.errorStatusCode}`.split(
            '://'
          )[1]
      } else {
        url = fullUrl
      }
      return [protocol, url, fullUrl]
    },
    getPageNumberOfTotal(params) {
      return params.pageNumberOfTotal || null
    },
    buildParamsForPixel(params) {
      const userState = this.getUserState()
      const [protocol, url, fullUrl] = this.getProtocolAndUrl(params)
      const pageNumberOfTotal = this.getPageNumberOfTotal(params)
      return {
        customParameter: {
          protocol,
          zeitSso: userState,
          pageNumberQ3: pageNumberOfTotal,
          pageNumberJobMarkets: pageNumberOfTotal,
          fullUrl
        },
        pageType: params.pageType,
        cluster: params.cluster,
        url
      }
    },
    getBreakpoint() {
      if (window.innerWidth >= 1024) {
        return 'stationaer'
      } else if (window.innerWidth >= 640) {
        return 'tablet'
      } else {
        return 'mobile'
      }
    },
    validTrackingParameters(params) {
      return REQUIRED_CLICKTRACK_PARAMETERS.every(
        key =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ''
      )
    }
  }
}
</script>
