import _isEqual from 'lodash/isEqual'
import _merge from 'lodash/merge'
import _hasIn from 'lodash/hasIn'
import _transform from 'lodash/transform'
import { CLICK_TRACKING_PARAMS } from '@/lib/tracking/webtrekk/constants'

export const trackingContext = {
  bind: (el, binding, _vnode) => {
    el.dataset.boaContext = JSON.stringify(mapConfig(binding.value))
  },
  update: (el, binding, _vnode) => {
    if (!_isEqual(binding.oldValue, binding.value)) {
      el.dataset.boaContext = JSON.stringify(mapConfig(binding.value))
    }
  }
}

const mapConfig = value => {
  return _transform(value, (acc, val, key) => {
    acc[CLICK_TRACKING_PARAMS[key]] = String(val)
  })
}

const getTrackingContext = el => {
  return getParents(el)
    .map(el => el.dataset.boaContext)
    .map(el => JSON.parse(el))
    .reduce((acc, el) => _merge(acc, el), {})
}

const getParents = el => {
  // eslint-disable-next-line no-var
  for (var parents = []; el; el = el.parentNode) {
    parents.push(el)
  }

  return parents.reverse().filter(el => _hasIn(el, ['dataset', 'boaContext']))
}

export const manualTrack = (el, config) => {
  const params = _merge(getTrackingContext(el), mapConfig(config))
  window.$nuxt.$emit('trackClick', params)
}

export const clickTrack = {
  bind: (el, binding, _vnode) => {
    el.$clickTrackData = binding.value
    const handler = () => {
      manualTrack(el, el.$clickTrackData)
    }
    el.addEventListener('click', handler)
  },
  update: (el, binding, _vnode) => {
    el.$clickTrackData = binding.value
  }
}
