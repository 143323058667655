function scrollable(disable) {
  const elements = [document.body, document.documentElement]
  elements.forEach(element =>
    element.classList.toggle('overlayable-disable-scroll', disable)
  )
}

export default {
  methods: {
    overlayable(disableScroll) {
      scrollable(disableScroll)
    }
  }
}
