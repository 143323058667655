export default function ({ app, error }) {
  const {
    $config: { GRAPHQL_URL, BROWSER_GRAPHQL_URL }
  } = app

  return {
    // required
    httpEndpoint: GRAPHQL_URL,
    // You can use a link to add custom behaviour on error
    // link: from([link]),
    // optional
    // override HTTP endpoint in browser only
    browserHttpEndpoint: BROWSER_GRAPHQL_URL,
    // optional
    // See https://www.apollographql.com/docs/link/links/http.html#options
    httpLinkOptions: {
      credentials: 'same-origin'
    },
    // NOTE: We're not curently using the apollo cache, so this should be fine to disable
    inMemoryCacheOptions: {
      addTypename: false
    }
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: 'ws://localhost:4000', // optional
    // LocalStorage token
    // tokenName: 'apollo-token', // optional
    // Enable Automatic Query persisting with Apollo Engine
    // persisting: false, // Optional
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    // websocketsOnly: false // Optional
  }
}
