export default function ({ app, redirect, route }) {
  const email = app.store.getters['user/email']

  if (!email) {
    return redirect({
      name: 'anmelden___de',
      query: { ...route.query, redirect: route.path }
    })
  }
}
