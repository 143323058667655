import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=74196163&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=74196163&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../base_image/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74196163",
  null
  
)

export default component.exports