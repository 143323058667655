import { loadScript } from '@/lib/util/load-script'

export const KNOWN_VENDORS = [
  'WEBTREKK',
  'GOOGLE_MAPS',
  'GOOGLE',
  'LINKEDIN',
  'FACEBOOK'
]

export const state = () => ({
  // We'll assume Webtrekk consent for now
  vendorConsents: ['WEBTREKK'],
  gtmTrackingLoaded: false,
  sourcepointLoaded: false
})

export const mutations = {
  vendorConsentsAdd(state, vendor) {
    if (KNOWN_VENDORS.includes(vendor)) {
      const vendors = new Set(state.vendorConsents)
      vendors.add(vendor)
      state.vendorConsents = [...vendors]
    }
  },
  vendorConsentsRemove(state, vendor) {
    const vendors = new Set(state.vendorConsents)
    vendors.delete(vendor)
    state.vendorConsents = [...vendors]
  },
  gtmTrackingLoadedSet(state, loaded) {
    state.gtmTrackingLoaded = loaded
  },
  sourcepointIsLoaded(state) {
    state.sourcepointLoaded = true
  },
  sourcepointNotLoaded(state) {
    state.sourcepointLoaded = false
  }
}

export const actions = {
  vendorConsent({ commit }, vendor) {
    commit('vendorConsentsAdd', vendor)
  },
  vendorForbid({ commit }, vendor) {
    commit('vendorConsentsRemove', vendor)
  },
  privacyModalOpen() {
    if (window && window._sp_ && window._sp_.privacyModalOpen) {
      window._sp_.privacyModalOpen()
    }
  },
  gtmTrackingSetLoaded({ commit }, loaded) {
    commit('gtmTrackingLoadedSet', loaded)
  },
  loadSourcepoint({ state, commit }) {
    return loadScript(
      'https://cdn.privacy-mgmt.com/wrapperMessagingWithoutDetection.js'
    )
      .then(_response => commit('sourcepointIsLoaded'))
      .catch(() => commit('sourcepointNotLoaded'))
  }
}

export const getters = {
  // Generate getters for known vendors
  ...KNOWN_VENDORS.reduce((acc, vendor) => {
    acc[`consentFor${vendor}`] = function (state) {
      return state.vendorConsents.includes(vendor)
    }
    return acc
  }, {}),
  getActiveVendors: state => state.vendorConsents,
  getGtmTrackingLoaded: state => state.gtmTrackingLoaded,
  getSourcepointLoaded: state => state.sourcepointLoaded
}
