import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _541ee446 = () => interopDefault(import('../pages/abbestellen.vue' /* webpackChunkName: "pages/abbestellen" */))
const _959df9bc = () => interopDefault(import('../pages/anmelden.vue' /* webpackChunkName: "pages/anmelden" */))
const _45094eb4 = () => interopDefault(import('../pages/einstellungen/index.vue' /* webpackChunkName: "pages/einstellungen/index" */))
const _8332f282 = () => interopDefault(import('../pages/suchprofil.vue' /* webpackChunkName: "pages/suchprofil" */))
const _8adc6762 = () => interopDefault(import('../pages/testergebnis.vue' /* webpackChunkName: "pages/testergebnis" */))
const _5fe8dc6c = () => interopDefault(import('../pages/einstellungen/benachrichtigungen.vue' /* webpackChunkName: "pages/einstellungen/benachrichtigungen" */))
const _300c69b4 = () => interopDefault(import('../pages/einstellungen/profildaten.vue' /* webpackChunkName: "pages/einstellungen/profildaten" */))
const _1741ce32 = () => interopDefault(import('../pages/jobs/_search_code.vue' /* webpackChunkName: "pages/jobs/_search_code" */))
const _fb05ddba = () => interopDefault(import('../pages/berufstest/_section/_page.vue' /* webpackChunkName: "pages/berufstest/_section/_page" */))
const _0ff684f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abbestellen",
    component: _541ee446,
    pathToRegexpOptions: {"strict":true},
    name: "abbestellen___de"
  }, {
    path: "/anmelden",
    component: _959df9bc,
    pathToRegexpOptions: {"strict":true},
    name: "anmelden___de"
  }, {
    path: "/einstellungen",
    component: _45094eb4,
    pathToRegexpOptions: {"strict":true},
    name: "einstellungen___de"
  }, {
    path: "/suchprofil",
    component: _8332f282,
    pathToRegexpOptions: {"strict":true},
    name: "suchprofil___de"
  }, {
    path: "/testergebnis",
    component: _8adc6762,
    pathToRegexpOptions: {"strict":true},
    name: "testergebnis___de"
  }, {
    path: "/einstellungen/benachrichtigungen",
    component: _5fe8dc6c,
    pathToRegexpOptions: {"strict":true},
    name: "einstellungen-benachrichtigungen___de"
  }, {
    path: "/einstellungen/profildaten",
    component: _300c69b4,
    pathToRegexpOptions: {"strict":true},
    name: "einstellungen-profildaten___de"
  }, {
    path: "/jobs/:search_code?",
    component: _1741ce32,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-search_code___de"
  }, {
    path: "/berufstest/:section?/:page?",
    component: _fb05ddba,
    pathToRegexpOptions: {"strict":true},
    name: "berufstest-section-page___de"
  }, {
    path: "/",
    component: _0ff684f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
