<template>
  <div />
</template>

<script>
import { mapGetters } from 'vuex'

import Ivw from '@/lib/tracking/ivw_pixel.js'

export default {
  computed: {
    ...mapGetters('consent', ['getSourcepointLoaded']),
    debugOnly() {
      return this.$config.BENU_STAGE !== 'prod'
    },
    toWatch() {
      return [this.getSourcepointLoaded, this.$route]
    }
  },
  watch: {
    toWatch: {
      handler() {
        this.send()
      },
      immediate: true
    }
  },
  methods: {
    send() {
      if (this.$isServer || !this.getSourcepointLoaded) {
        return
      }
      Ivw.send(this.$route.path, this.debugOnly)
    }
  }
}
</script>
