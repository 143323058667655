const middleware = {}

middleware['authenticate'] = require('../middleware/authenticate.js')
middleware['authenticate'] = middleware['authenticate'].default || middleware['authenticate']

middleware['jobmail-ready'] = require('../middleware/jobmail-ready.js')
middleware['jobmail-ready'] = middleware['jobmail-ready'].default || middleware['jobmail-ready']

middleware['load-user'] = require('../middleware/load-user.js')
middleware['load-user'] = middleware['load-user'].default || middleware['load-user']

middleware['questions'] = require('../middleware/questions.js')
middleware['questions'] = middleware['questions'].default || middleware['questions']

middleware['referrer'] = require('../middleware/referrer.js')
middleware['referrer'] = middleware['referrer'].default || middleware['referrer']

middleware['save-quiz'] = require('../middleware/save-quiz.js')
middleware['save-quiz'] = middleware['save-quiz'].default || middleware['save-quiz']

middleware['user-state'] = require('../middleware/user-state.js')
middleware['user-state'] = middleware['user-state'].default || middleware['user-state']

export default middleware
