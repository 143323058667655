import _isEqual from 'lodash/isEqual'
import _merge from 'lodash/merge'
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'

import jobListQuery from './graphql/queries/job-list.gql'
import jobListFilterQuery from './graphql/queries/job-list-filter.gql'
import { workplacesDefaultValues } from '@/lib/data/workplaces.js'
import {
  filterCodeValid,
  codeToJobFilter,
  jobFilterToCode
} from '@/lib/util/encrypt-filters.js'
import {
  omitEmpties,
  userProfileToJobsFilter
} from '@/lib/util/search-filters.js'
import {
  silentRoutePush,
  silentRouteReplace
} from '@/lib/util/navigation-less-route.js'

export const emptySearchFilters = {
  jobFields: [],
  jobLevels: [],
  careerLevel: null,
  workplaces: workplacesDefaultValues()
}

export const state = () => ({
  jobList: [],
  totalJobs: 0,
  searchFilters: emptySearchFilters,
  searchLimit: 25,
  searchOffset: 0,
  searchCode: { value: null, action: null }
})

export const mutations = {
  jobListSet(state, jobList) {
    state.jobList = jobList
  },
  totalJobsSet(state, total) {
    state.totalJobs = total
  },
  filterAssign(state, change) {
    Object.entries(change).reduce((profile, [key, value]) => {
      _set(profile, key, value)
      return profile
    }, state.searchFilters)
  },
  filterSet(state, filter) {
    state.searchFilters = filter
  },
  searchOffsetSet(state, searchOffset) {
    state.searchOffset = searchOffset
  },
  searchCodeSet(state, searchCode) {
    state.searchCode = searchCode
  }
}

export const actions = {
  jobListLoad({ commit }) {
    return this.app.apolloProvider.defaultClient
      .query({ query: jobListQuery, fetchPolicy: 'network-only' })
      .then(({ data }) => {
        commit('jobListSet', data.jobList)
      })
  },
  async jobListFilterInitialLoad(
    { rootState, commit, dispatch },
    initialSearchCode
  ) {
    if (filterCodeValid(initialSearchCode)) {
      commit('searchCodeSet', { value: initialSearchCode, action: 'none' })
      commit('filterSet', codeToJobFilter(initialSearchCode))
    } else {
      const filter = userProfileToJobsFilter(rootState.user.profile)
      const searchCode = jobFilterToCode(filter)
      commit('searchCodeSet', { value: searchCode, action: 'replace' })
      commit('filterSet', filter)
      silentRouteReplace(this.app.router, {
        params: { search_code: searchCode }
      })
    }

    return await dispatch('jobListFilterLoad')
  },
  async jobListFilterLoad({ state, commit, dispatch }) {
    commit('searchOffsetSet', 0)

    return await dispatch('jobListFilterLoadReally')
  },
  async jobListFilterLoadReally({ state, commit, dispatch, getters }) {
    const variables = {
      filter: omitEmpties(state.searchFilters),
      offset: state.searchOffset,
      limit: state.searchLimit
    }

    const result = await this.app.apolloProvider.defaultClient.query({
      query: jobListFilterQuery,
      variables,
      fetchPolicy: 'network-only'
    })

    if (result && result.data) {
      commit('jobListSet', result.data.jobListFilter.jobs)
      commit('totalJobsSet', result.data.jobListFilter.total)
    }
  },
  jobListOffsetLoad({ commit, dispatch }, offset) {
    commit('searchOffsetSet', offset)
    return dispatch('jobListFilterLoadReally')
  },
  jobListFilterSet({ commit, dispatch }, filter) {
    const searchCode = jobFilterToCode(filter)
    commit('filterSet', filter)
    commit('searchCodeSet', { value: searchCode, action: 'push' })
    silentRoutePush(this.app.router, { params: { search_code: searchCode } })
    return dispatch('jobListFilterLoad')
  },
  jobListFilterAssign({ commit, dispatch }, change) {
    commit('filterAssign', change)
    return dispatch('updateSearchCode')
  },
  updateSearchCode({ state, commit, dispatch }) {
    const searchCode = jobFilterToCode(state.searchFilters)
    commit('searchCodeSet', { value: searchCode, action: 'push' })
    silentRoutePush(this.app.router, { params: { search_code: searchCode } })
    return dispatch('jobListFilterLoad')
  },
  jobListFilterReset({ commit, dispatch }) {
    commit('filterAssign', emptySearchFilters)
    return dispatch('updateSearchCode')
  }
}

export const getters = {
  searchCode: state => {
    return state.searchCode.value
  },
  jobList: state => {
    return state.jobList
  },
  hasJobsCountRelevantFilters: state => {
    // isFlexible does not influence count of jobResults and will be ignored for matchingJobsCount
    const jobsCountIrrelevantFilters = _merge(_cloneDeep(emptySearchFilters), {
      workplaces: { isFlexible: state.searchFilters.workplaces.isFlexible }
    })
    return !_isEqual(state.searchFilters, jobsCountIrrelevantFilters)
  }
}
