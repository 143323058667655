<template>
  <div class="container">
    <h1 class="title">
      {{ errorTitle }}
    </h1>
    <p class="description">
      {{ errorDescription }}
    </p>
    <NuxtLink class="back-home" to="/">
      {{ $t('pages.errors.backHome') }}
    </NuxtLink>
  </div>
</template>

<script>
import WebtrekkMixin from '@/components/tracking/webtrekk-mixin.js'

export default {
  mixins: [WebtrekkMixin],
  props: { error: { type: Object, required: true } },
  head() {
    return {
      title: this.errorTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.errorDescription
        }
      ],
      noscript: [{ innerHTML: this.noScriptPixels, body: true }]
    }
  },
  computed: {
    webtrekk() {
      return {
        pageType: 'error',
        cluster: '',
        errorStatusCode: this.error.statusCode
      }
    },
    errorTitle() {
      return this.i18nLookup(this.error, 'title')
    },
    errorDescription() {
      return this.i18nLookup(this.error, 'description')
    }
  },
  methods: {
    i18nLookup(error, item) {
      const lookup = `pages.errors.codes.${error.statusCode}.${item}`
      if (error.statusCode && this.$te(lookup)) {
        return this.$t(lookup)
      }

      return this.$te(`pages.errors.codes.generic.${item}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  padding: spacing(3);
}

.title {
  @include headline-1;

  margin-bottom: spacing(2);
}

.back-home {
  @include white-button-with-border;

  margin-top: spacing(6);
}
</style>
