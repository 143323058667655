export default [
  {
    label: 'Administration, Sekretariat und Verwaltung',
    value: 'BUSINESS_ADMINISTRATION',
    codeValue: 1
  },
  {
    label: 'Agrar- und Landwirtschaft',
    value: 'AGRICULTURE_AND_FARMING',
    codeValue: 52
  },
  { label: 'Architektur', value: 'ARCHITECTURE', codeValue: 2 },
  { label: 'Bankwesen', value: 'BANKING', codeValue: 3 },
  { label: 'Bauwesen', value: 'CONSTRUCTION', codeValue: 4 },
  { label: 'Beratung / Consulting', value: 'CONSULTING', codeValue: 5 },
  { label: 'Bildung und Lehre', value: 'EDUCATION', codeValue: 6 },
  {
    label: 'Customer Service und Kundenbetreuung',
    value: 'CUSTOMER_CARE',
    codeValue: 7
  },
  { label: 'Data Science', value: 'DATA_SCIENCE', codeValue: 8 },
  { label: 'Design und Gestaltung', value: 'DESIGN', codeValue: 9 },
  { label: 'E-Commerce', value: 'E_COMMERCE', codeValue: 10 },
  {
    label: 'Einkauf / Category Management',
    value: 'PURCHASING_MANAGEMENT',
    codeValue: 11
  },
  { label: 'Elektromobilität', value: 'ELECTROMOBILITY', codeValue: 12 },
  { label: 'Elektrotechnik', value: 'ELECTRICAL_ENGINEERING', codeValue: 13 },
  { label: 'Eventmanagement', value: 'EVENT_MANAGEMENT', codeValue: 14 },
  { label: 'Finanzen', value: 'FINANCE', codeValue: 15 },
  { label: 'Handelswesen', value: 'COMMERCE', codeValue: 55 },
  {
    label: 'Forschung und Entwicklung',
    value: 'RESEARCH_DEVELOPMENT',
    codeValue: 16
  },
  { label: 'Gastronomie und Hotellerie', value: 'GASTRONOMY', codeValue: 18 },
  { label: 'Immobilien', value: 'REAL_ESTATE', codeValue: 19 },
  { label: 'Ingenieurwesen', value: 'ENGINEERING', codeValue: 20 },
  { label: 'IT', value: 'IT', codeValue: 21 },
  {
    label: 'IT Administration / Support',
    value: 'IT_ADMINISTRATION',
    codeValue: 22
  },
  { label: 'IT Beratung', value: 'IT_CONSULTING', codeValue: 23 },
  {
    label: 'IT Softwareentwicklung / Programmierung',
    value: 'SOFTWARE_DEVELOPMENT',
    codeValue: 24
  },
  {
    label: 'Journalismus / Redaktion / Lektorat',
    value: 'EDITORIAL',
    codeValue: 25
  },
  {
    label: 'Kunst und Unterhaltung',
    value: 'ARTS_AND_ENTERTAINMENT',
    codeValue: 54
  },
  { label: 'Labor', value: 'LABORATORY', codeValue: 26 },
  { label: 'Lager und Logistik', value: 'LOGISTICS', codeValue: 27 },
  {
    label: 'Management und Unternehmensentwicklung',
    value: 'CORPORATE_DEVELOPMENT',
    codeValue: 28
  },
  { label: 'Marketing und Werbung', value: 'MARKETING', codeValue: 29 },
  { label: 'Marktforschung', value: 'MARKET_RESEARCH', codeValue: 30 },
  { label: 'Medien', value: 'MEDIA', codeValue: 56 },
  {
    label: 'Medizin und Gesundheitsmanagement',
    value: 'HEALTH_MANAGEMENT',
    codeValue: 31
  },
  { label: 'Medizintechnik', value: 'MEDICAL_TECHNOLOGY', codeValue: 32 },
  { label: 'Pädagogik und Erziehung', value: 'PEDAGOGY', codeValue: 33 },
  {
    label: 'Personal / Human Ressources und Recruiting',
    value: 'HUMAN_RESOURCES',
    codeValue: 34
  },
  { label: 'Pflege, Therapie und Assistenz', value: 'CARE', codeValue: 35 },
  {
    label: 'PR, Öffentlichkeitsarbeit und Kommunikation',
    value: 'PUBLIC_RELATION',
    codeValue: 36
  },
  {
    label: 'Produkt- und Prozessentwicklung',
    value: 'PRODUCT_DEVELOPMENT',
    codeValue: 37
  },
  { label: 'Produktion, Service und Handwerk', value: 'CRAFT', codeValue: 38 },
  { label: 'Produktmanagement', value: 'PRODUCT_MANAGEMENT', codeValue: 39 },
  { label: 'Projektmanagement', value: 'PROJECT_MANAGEMENT', codeValue: 40 },
  { label: 'Psychologie', value: 'PSYCHOLOGY', codeValue: 41 },
  { label: 'Qualitätsmanagement', value: 'QUALITY_MANAGEMENT', codeValue: 42 },
  {
    label: 'Rechnungswesen und Controlling',
    value: 'ACCOUNTING_AND_CONTROLLING',
    codeValue: 53
  },
  { label: 'Recht', value: 'LAW', codeValue: 43 },
  { label: 'Social Media', value: 'SOCIAL_MEDIA', codeValue: 44 },
  { label: 'Soziales', value: 'SOCIAL_SERVICES', codeValue: 57 },
  {
    label: 'Stadt- und Verkehrsplanung',
    value: 'CITY_PLANNING',
    codeValue: 45
  },
  { label: 'Steuerwesen', value: 'TAXATION', codeValue: 46 },
  { label: 'Tourismus und Reisen', value: 'TOURISM', codeValue: 47 },
  {
    label: 'Umwelt- / Energiemanagement',
    value: 'ENVIRONMENTAL_MANAGEMENT',
    codeValue: 48
  },
  { label: 'Versicherungen', value: 'INSURANCE', codeValue: 49 },
  { label: 'Vertrieb und Verkauf', value: 'SALES', codeValue: 50 },
  {
    label: 'Wissenschaft und Forschung',
    value: 'RESEARCH_SCIENCE',
    codeValue: 51
  }
]
