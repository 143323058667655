<template>
  <div />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

const accountId = 692
const subProdStages = {
  accountId,
  privacyManagerId: 394957,
  propertyId: 14471
}

const mapping = {
  local: subProdStages,
  dev: subProdStages,
  staging: subProdStages,
  prod: {
    accountId,
    privacyManagerId: 396355,
    propertyId: 14321
  }
}

export default {
  head() {
    return {
      script: [
        {
          body: true,
          type: 'text/javascript',
          innerHTML:
            '"use strict";function _typeof(t){return(_typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(t){return typeof t}:function(t){return t&&"function"==typeof Symbol&&t.constructor===Symbol&&t!==Symbol.prototype?"symbol":typeof t})(t)}!function(){var t=function(){var t,e,o=[],n=window,r=n;for(;r;){try{if(r.frames.__tcfapiLocator){t=r;break}}catch(t){}if(r===n.top)break;r=r.parent}t||(!function t(){var e=n.document,o=!!n.frames.__tcfapiLocator;if(!o)if(e.body){var r=e.createElement("iframe");r.style.cssText="display:none",r.name="__tcfapiLocator",e.body.appendChild(r)}else setTimeout(t,5);return!o}(),n.__tcfapi=function(){for(var t=arguments.length,n=new Array(t),r=0;r<t;r++)n[r]=arguments[r];if(!n.length)return o;"setGdprApplies"===n[0]?n.length>3&&2===parseInt(n[1],10)&&"boolean"==typeof n[3]&&(e=n[3],"function"==typeof n[2]&&n[2]("set",!0)):"ping"===n[0]?"function"==typeof n[2]&&n[2]({gdprApplies:e,cmpLoaded:!1,cmpStatus:"stub"}):o.push(n)},n.addEventListener("message",(function(t){var e="string"==typeof t.data,o={};if(e)try{o=JSON.parse(t.data)}catch(t){}else o=t.data;var n="object"===_typeof(o)&&null!==o?o.__tcfapiCall:null;n&&window.__tcfapi(n.command,n.version,(function(o,r){var a={__tcfapiReturn:{returnValue:o,success:r,callId:n.callId}};t&&t.source&&t.source.postMessage&&t.source.postMessage(e?JSON.stringify(a):a,"*")}),n.parameter)}),!1))};"undefined"!=typeof module?module.exports=t:t()}();',
          hid: 'consent-script'
        }
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'consent-script': ['innerHTML']
      }
    }
  },
  computed: {
    spConfig() {
      return mapping[this.$config.BENU_STAGE]
    },
    ...mapGetters('consent', ['getGtmTrackingLoaded', 'getActiveVendors'])
  },
  mounted() {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    if (!this.spConfig) {
      window.dataLayer.push({
        event: 'cmpEvent',
        cmpCustomVendorsConsent: this.getActiveVendors,
        cmpVendorsConsent: [],
        cmpPurposesConsent: []
      })
      return
    }

    window._sp_ = {
      config: {
        accountId: this.spConfig.accountId,
        propertyId: this.spConfig.propertyId,
        baseEndpoint: 'https://cmp.berufstest.zeit.de'
      },
      privacyModalOpen: () => {
        window._sp_.loadPrivacyManagerModal(this.spConfig.privacyManagerId)
      }
    }

    window._sp_.config.events = {
      onConsentReady: (consentUUID, euconsent) => {
        if (this.getGtmTrackingLoaded) {
          window.__tcfapi('addEventListener', 2, (tcdata, success) => {
            if (success) {
              const cs = Object.entries((tcdata.vendor || {}).consents)
                .filter(function (v) {
                  return v[1]
                })
                .map(function (v) {
                  return v[0]
                })
              const ps = Object.entries((tcdata.purpose || {}).consents)
                .filter(function (p) {
                  return p[1]
                })
                .map(function (p) {
                  return p[0]
                })
              window.dataLayer.push({
                event: 'cmpEvent',
                cmpCustomVendorsConsent: this.getActiveVendors,
                cmpVendorsConsent: cs,
                cmpPurposesConsent: ps
              })
            }
          })
        }
      }
    }

    window._sp_.addCustomVendor = vendor => {
      this.$store.dispatch('consent/vendorConsent', vendor)
    }

    window._sp_.removeCustomVendor = vendor => {
      this.$store.dispatch('consent/vendorForbid', vendor)
    }

    this.loadSourcepoint()
  },
  methods: {
    ...mapActions('consent', ['loadSourcepoint'])
  }
}
</script>
