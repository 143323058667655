<template>
  <div class="top-bar">
    <div class="top-bar-left">
      <ul class="menu">
        <li>
          <a :href="boaLink">
            <img
              width="191"
              height="101"
              class="logo logo-boa"
              src="~/assets/logos/boa-logo.svg"
              :alt="$t('components.containers.navigation.boaLogoAlt')"
            />
          </a>
        </li>
        <li>
          <img
            width="571"
            height="190"
            class="logo logo-powered-by"
            src="~/assets/logos/zeit-campus-logo-powered.png"
            :alt="$t('components.containers.navigation.poweredByAlt')"
          />
        </li>
        <li class="burger">
          <img :src="navIcon" @click="navToggle" />
        </li>
      </ul>
    </div>
    <div class="top-bar-right">
      <ul class="menu" :class="{ open: isNavOpen }">
        <li>
          <UserState />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserState from '@/components/controls/UserState'
import burgerIcon from '@/assets/sprite/svg/burger.svg'
import closeIcon from '@/assets/sprite/svg/close-black.svg'
import Overlayable from '@/mixins/overlayable'

export default {
  name: 'TopBar',
  components: { UserState },
  mixins: [Overlayable],
  computed: {
    ...mapGetters('app', ['isNavOpen']),
    boaLink() {
      return this.$config.PRODUCTION_URL
    },
    navIcon() {
      return this.isNavOpen ? closeIcon : burgerIcon
    }
  },
  watch: {
    $route(to, from) {
      if (this.isNavOpen) {
        this.$store.dispatch('app/navToggle')
      }
    },
    isNavOpen(state) {
      this.overlayable(state)
    }
  },
  methods: {
    ...mapActions('app', ['navToggle'])
  }
}
</script>

<style lang="scss" scoped>
@include foundation-top-bar;
@include foundation-menu;

.top-bar {
  @include breakpoint(medium down) {
    padding: 0;
  }

  @include breakpoint(large) {
    border-bottom: 1px solid color(gray, light);
  }
}

.menu {
  > li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    color: color(black, base);
    text-transform: uppercase;
  }
}

.top-bar-left {
  position: relative;

  @include breakpoint(medium down) {
    border-bottom: 1px solid color(gray, light);
    padding: $topbar-padding;
  }
}

.top-bar-right {
  position: relative;

  .menu {
    @include breakpoint(medium down) {
      display: none;
    }
  }

  li {
    width: 100%;
  }

  .menu.open {
    @include breakpoint(medium down) {
      @include z-index(top-bar);

      display: block;
      position: absolute;
      top: -7px;
      left: 0;
      width: 100%;
      max-height: 201px;
      border-top: 1px solid color(gray, light);
    }
  }
}

.logo-boa {
  max-height: 32px;
  width: auto;

  @include breakpoint(medium only) {
    max-height: 43px;
    margin-left: spacing(2);
  }

  @include breakpoint(large) {
    max-height: 56px;
  }
}

.logo-powered-by {
  max-width: 55px;
  height: auto;
  margin-left: spacing(2);

  @include breakpoint(medium) {
    max-width: 77px;
    margin-left: spacing(3);
  }

  @include breakpoint(large) {
    max-width: 100px;
    margin-left: spacing(4);
  }
}

.burger {
  position: absolute;
  top: 4px;
  right: 0;
  cursor: pointer;

  @include breakpoint(medium) {
    top: 10px;
    padding-right: spacing(2);
  }

  @include breakpoint(large) {
    display: none !important;
  }
}
</style>
