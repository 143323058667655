import Vue from 'vue'

const SvgSpriteIcon = Vue.component('SvgSpriteIcon', {
  props: {
    name: {
      required: true,
      type: String
    },
    title: {
      type: String,
      default: null
    }
  },
  render(createElement) {
    const children = [
      createElement('use', { attrs: { href: `#i-${this.name}` } })
    ]

    if (this.title) {
      children.unshift(createElement('title', {}, this.title))
    }

    return createElement(
      'svg',
      { staticClass: 'icon', attrs: { role: 'img' } },
      children
    )
  }
})

Vue.component('Icon', SvgSpriteIcon)
