<template>
  <div class="menu-bar">
    <div class="menu-bar__content">
      <NuxtLink
        v-for="item in isQuizComplete
          ? menuItemsTestComplete
          : menuItemsTestIncomplete"
        :key="item.name"
        class="menu-bar__items"
        :class="{ 'menu-bar__items-active': onCurrentPage(item.path) }"
        :to="{ name: item.to, params: item.params }"
      >
        <div class="menu-bar__item">
          <icon
            v-if="onCurrentPage(item.path)"
            :name="item.icon_active"
            class="menu-bar__icon"
          />
          <icon v-else :name="item.icon_inactive" class="menu-bar__icon" />
          <p class="menu-bar__item__text">
            {{ item.name }}
          </p>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuBar',
  data() {
    return {
      menuItemsTestComplete: [
        {
          key: 'jobs',
          name: 'Jobs',
          to: 'jobs-search_code___de',
          icon_inactive: 'search-inactive',
          icon_active: 'search-active',
          path: '/jobs'
        },
        {
          key: 'searchprofile',
          name: 'Suchprofil',
          to: 'suchprofil___de',
          icon_inactive: 'searchprofile-inactive',
          icon_active: 'searchprofile-active',
          path: '/suchprofil'
        },
        {
          key: 'testresult',
          name: 'Testergebnis',
          to: 'testergebnis___de',
          icon_inactive: 'testresults-inactive',
          icon_active: 'testresults-active',
          path: '/testergebnis'
        },
        {
          key: 'einstellungen',
          name: 'Mehr',
          to: 'einstellungen___de',
          icon_inactive: 'more-inactive',
          icon_active: 'more-active',
          path: '/einstellungen'
        }
      ],
      menuItemsTestIncomplete: [
        {
          key: 'jobs',
          name: 'Jobs',
          to: 'jobs-search_code___de',
          icon_inactive: 'search-inactive',
          icon_active: 'search-active',
          path: '/jobs'
        },
        {
          key: 'searchprofile',
          name: 'Suchprofil',
          to: 'suchprofil___de',
          icon_inactive: 'searchprofile-inactive',
          icon_active: 'searchprofile-active',
          path: '/suchprofil'
        },
        {
          key: 'test',
          name: 'Zum Berufstest',
          to: 'berufstest-section-page___de',
          params: { section: 'berufserfahrung', page: 1 },
          icon_inactive: 'berufstest-inactive',
          icon_active: 'berufstest-active',
          path: '/berufstest'
        },
        {
          key: 'einstellungen',
          name: 'Mehr',
          to: 'einstellungen___de',
          icon_inactive: 'more-inactive',
          icon_active: 'more-active',
          path: '/einstellungen'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['isQuizComplete'])
  },
  methods: {
    onCurrentPage(path) {
      const basename = '/' + this.$nuxt.$route.path.split('/')[1]
      return basename === path
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-bar {
  @include z-index(menu-bar);

  background-color: white;
  position: sticky;
  top: 0;
  border-bottom: 1px solid color(gray, light);

  @include breakpoint(medium down) {
    padding: 0;
  }

  @include breakpoint(medium up) {
    border-bottom: 2px solid color(gray, light);
  }

  &__content {
    @include meta;

    // offset in #totalResult anchor (TotalResult.vue) depends on this height
    // offset in #filters anchor (MobileFilters.vue) depends on this height
    height: spacing(8);
    font-weight: 600;
    color: color(gray, dark);
    display: flex;
    margin: auto;
    justify-content: space-between;
    min-width: 20%;

    @include breakpoint(large) {
      max-width: $container-max-width;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $dark-gray;
    width: 100%;
    height: spacing(8);

    &-active {
      color: color(primary, base);
      position: relative;

      &::after {
        --radius: 4px;

        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        border-top: var(--radius) solid color(primary, base);
        border-top-left-radius: var(--radius);
        border-top-right-radius: var(--radius);
        mix-blend-mode: multiply;

        @include breakpoint(medium up) {
          --radius: 6px;

          bottom: -2px;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    &__text {
      @include body-text;

      font-size: font-size(-2);

      @include breakpoint(medium) {
        font-size: font-size(-1);
      }
    }
  }

  &__icon {
    width: 26px;
    height: 26px;
    margin: 6px;
  }
}
</style>
