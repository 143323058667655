import jobFields from '@/lib/data/job-fields'
import { jobLevelProfile } from '@/lib/data/job-level'
import careerLevel from '@/lib/data/career-level.js'

export function filterCodeValid(code) {
  try {
    JSON.parse(decode(code))
  } catch (e) {
    return false
  }
  return true
}

export function codeToJobFilter(code) {
  return extractCode(JSON.parse(decode(code)))
}

export function jobFilterToCode(filter) {
  return encode(JSON.stringify(compressFilter(filter)))
}

function decode(code) {
  let decoder
  if (process.browser) {
    decoder = window.atob
  } else {
    decoder = string => Buffer.from(string, 'base64').toString()
  }

  return decoder(code)
}

function encode(code) {
  let encoder
  if (process.browser) {
    encoder = window.btoa
  } else {
    encoder = string => Buffer.from(string, 'utf8').toString('base64')
  }

  return encoder(code)
}

function compressFilter(filter) {
  const compressedFilter = {}

  // jobFields
  const jobFieldsInFilter = filter.jobFields
  if (jobFieldsInFilter.length > 0) {
    const jobFieldsCodeValues = []
    jobFieldsInFilter.forEach(jobFieldInFilter => {
      const jobFieldFound = jobFields.find(
        jobField => jobField.value === jobFieldInFilter
      )
      jobFieldsCodeValues.push(jobFieldFound.codeValue)
    })
    compressedFilter.JF = jobFieldsCodeValues
  }

  // jobLevels
  const jobLevelsInFilter = filter.jobLevels
  if (jobLevelsInFilter.length > 0) {
    const jobLevelsCodeValues = []
    jobLevelsInFilter.forEach(jobLevelInFilter => {
      const jobLevelFound = jobLevelProfile.find(
        jobLevel => jobLevel.value === jobLevelInFilter
      )
      jobLevelsCodeValues.push(jobLevelFound.codeValue)
    })
    compressedFilter.JL = jobLevelsCodeValues
  }

  // careerLevel
  const careerLevelInFilter = filter.careerLevel
  if (careerLevelInFilter) {
    const careerLevelEntry = careerLevel.find(
      level => level.value === careerLevelInFilter
    )
    compressedFilter.C = careerLevelEntry.codeValue
  }

  // workplaces -> workplaces: { isFlexible: true, perimeter: '10 km', selectedItems: ['22222 Hamburg'] }
  const workplacesInFilter = filter.workplaces
  if (workplacesInFilter) {
    const workplacesEntry = {
      I: workplacesInFilter.isFlexible,
      P: workplacesInFilter.perimeter.split(' km')[0],
      S: workplacesInFilter.selectedItems
    }
    compressedFilter.W = workplacesEntry
  }

  return compressedFilter
}

function extractCode(code) {
  const extractedCode = {}

  // jobFields -> for example [{ 'JF': ['ab','ad'] }
  const jobFieldsInCode = code.JF
  const jobFieldsValues = []
  if (jobFieldsInCode) {
    jobFieldsInCode.forEach(jobFieldInCode => {
      const jobFieldFound = jobFields.find(
        jobField => jobField.codeValue === jobFieldInCode
      )
      jobFieldFound && jobFieldsValues.push(jobFieldFound.value)
    })
  }
  extractedCode.jobFields = jobFieldsValues

  // jobLevels -> for example { 'JL': ['f', 'c', 'b'] }
  const jobLevelsInCode = code.JL
  const jobLevelsCodeValues = []
  if (jobLevelsInCode) {
    jobLevelsInCode.forEach(jobLevelInCode => {
      const jobLevelFound = jobLevelProfile.find(
        jobLevel => jobLevel.codeValue === jobLevelInCode
      )
      jobLevelFound && jobLevelsCodeValues.push(jobLevelFound.value)
    })
  }
  extractedCode.jobLevels = jobLevelsCodeValues

  // careerLevel -> for example { 'C': 'a'}
  const careerLevelInCode = code.C
  let careerLevelEntry = null
  if (careerLevelInCode) {
    const careerLevelFound = careerLevel.find(
      level => level.codeValue === careerLevelInCode
    )
    careerLevelFound && (careerLevelEntry = careerLevelFound.value)
  }
  extractedCode.careerLevel = careerLevelEntry

  // workplaces -> for example { 'W': { 'I': true, 'P': '10 km', 'S': [] } }
  const workplacesInCode = code.W
  if (workplacesInCode) {
    const workplacesEntry = {
      isFlexible: workplacesInCode.I,
      perimeter: workplacesInCode.P.toString() + ' km',
      selectedItems: workplacesInCode.S
    }
    extractedCode.workplaces = workplacesEntry
  }

  return extractedCode
}
