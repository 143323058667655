<template>
  <div class="footer">
    <div class="logos">
      <a :href="boaLink">
        <img
          width="191"
          height="101"
          class="footer__boa-logo"
          src="~/assets/logos/boa-logo.svg"
          :alt="$t('components.containers.navigation.boaLogoAlt')"
        />
      </a>
      <img
        width="571"
        height="190"
        class="footer__powered-by_side"
        src="~/assets/logos/zeit-campus-logo-powered.png"
        :alt="$t('components.containers.navigation.poweredByAlt')"
      />
    </div>
    <ul class="content">
      <li class="footer__category">
        {{ about.headline }}
      </li>
      <li class="footer__item">
        <a href="https://talent.zeit.de/" target="_blank">{{
          about.business
        }}</a>
      </li>
      <li class="footer__item">
        <a
          href="https://jobs.zeit.de/?wt_zmc=fix.int.zonaudev.boa.footer.stellenmarkt_startseite.textlink.link.x&utm_medium=fix&utm_source=boa_zonaudev_int&utm_campaign=footer&utm_content=stellenmarkt_startseite_textlink_link_x&layer=layer_generisch"
          target="_blank"
        >
          {{ about.zos }}
        </a>
      </li>
    </ul>
    <ul class="content">
      <li class="footer__category">
        {{ legal.headline }}
      </li>
      <li class="footer__item">
        <a
          href="https://www.zeit.de/administratives/boa-agb-b2b"
          target="_blank"
          >{{ legal.termsBusiness }}</a
        >
      </li>
      <li class="footer__item">
        <a href="https://datenschutz.zeit.de/boa" target="_blank">{{
          legal.privacy
        }}</a>
      </li>
      <li class="footer__item">
        <a href="#" @click.prevent="privacyModalOpen">{{ legal.cookies }}</a>
      </li>
      <li class="footer__item">
        <a href="https://www.zeit.de/impressum/index" target="_blank">{{
          legal.impressum
        }}</a>
      </li>
    </ul>
    <ul class="content">
      <li class="footer__category">
        {{ support.headline }}
      </li>
      <li class="footer__item support">
        {{ support.supportFirstLine }}<br />{{ support.supportSecondLine }}
        <a class="mail" href="mailto:boa@zeit.de" target="_blank">{{
          support.mail
        }}</a>
      </li>
    </ul>
    <img
      width="571"
      height="190"
      class="footer__powered-by_bottom"
      src="~/assets/logos/zeit-campus-logo-powered.png"
      :alt="$t('components.containers.navigation.poweredByAlt')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      ...this.$t('components.containers.footer')
    }
  },
  computed: {
    boaLink() {
      return this.$config.PRODUCTION_URL
    }
  },
  methods: {
    ...mapActions('consent', ['privacyModalOpen'])
  }
}
</script>

<style scoped lang="scss">
$footer-font-weight: 600;

.footer {
  background: color(white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: spacing(4);
  border-top: 1px solid color(gray, light);

  @include meta;

  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: flex-start;
    padding: spacing(6) spacing(2);
  }

  @include breakpoint(large) {
    padding: spacing(6) spacing(10);
  }

  a {
    text-decoration: none;
    color: color(black, base);
    transition: $transition-speed;

    &:hover {
      color: color(primary, base);
    }
  }

  .mail {
    font-weight: $footer-font-weight;
  }

  &__category {
    margin-top: spacing(4);
    font-weight: $footer-font-weight;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include breakpoint(medium up) {
      margin: 0;
    }
  }

  &__item {
    margin-top: typo-aware-spacing(2);

    @include breakpoint(medium) {
      white-space: nowrap;
    }
  }

  .support {
    white-space: normal;
  }

  &__boa-logo {
    width: 86px;
    height: auto;

    @include breakpoint(medium) {
      width: 91px;
    }

    @include breakpoint(large) {
      width: 67px;
    }
  }

  &__powered-by_bottom {
    width: 107px;
    height: auto;
    margin-top: spacing(4);

    @include breakpoint(medium up) {
      display: none;
    }
  }

  &__powered-by_side {
    display: none;

    @include breakpoint(medium) {
      max-height: 30.25px;
      width: auto;
      margin-top: spacing(4);
      display: block;
    }

    @include breakpoint(large) {
      margin-top: 0;
      display: block;
      margin-left: spacing(4);
    }
  }

  .content {
    @include breakpoint(medium) {
      margin: 0 spacing(2) 0;
    }

    @include breakpoint(large) {
      margin: 0 spacing(3) 0;
    }
  }

  .logos {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      margin: 0 spacing(3) 0 spacing(2);
    }

    @include breakpoint(large) {
      order: 4;
      margin-left: auto;
      flex-direction: row;
    }
  }
}
</style>
