import _isPlainObject from 'lodash/isPlainObject'
import _omitBy from 'lodash/omitBy'
import _isEqual from 'lodash/isEqual'
import _pick from 'lodash/pick'

import { workplacesDefaultValues } from '@/lib/data/workplaces.js'

export function userProfileToJobsFilter(profile) {
  return _pick(profile, ['jobLevels', 'careerLevel', 'jobFields', 'workplaces'])
}

function isEmpty(value) {
  return (
    value === '' ||
    value === null ||
    value === {} ||
    (Array.isArray(value) && value.length === 0) ||
    (_isPlainObject(value) && Object.keys(value).length === 0)
  )
}

export function omitEmpties(filter) {
  return _omitBy(filter, (value, key) => {
    return (
      (key === 'workplaces' && _isEqual(value, workplacesDefaultValues())) ||
      isEmpty(value)
    )
  })
}
