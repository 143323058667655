import Vue from 'vue'
import VueTooltip from 'v-tooltip'

Vue.use(VueTooltip)
VueTooltip.options.defaultTemplate =
  '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>'
VueTooltip.options.defaultArrowSelector =
  '.tooltip-vue-arrow, .tooltip-vue__arrow'
VueTooltip.options.defaultInnerSelector =
  '.tooltip-vue-inner, .tooltip-vue__inner'
VueTooltip.options.defaultTrigger = 'hover focus click'
